import ShippingVerification from "@/components/UI/Modal/Bodies/ShippingVerification/ShippingVerification.vue";
import ShippingNotes from "@/components/UI/Modal/Bodies/ShippingNotes/ShippingNotes.vue";
import ExportCustomerData from "@/components/UI/Modal/Bodies/ExportCustomerData/ExportCustomerData.vue";
import Assign from "./Bodies/Assign/Assign.vue";
import ApproveLaserTable from "./Bodies/ApproveLaserTable/ApproveLaserTable.vue";
import SendProof from "./Bodies/SendProof/SendProof.vue";
import Flag from "./Bodies/Flag/Flag.vue";
import ChangeStatus from "./Bodies/ChangeStatus/ChangeStatus.vue";
import Redo from "./Bodies/Redo/Redo.vue";
import Vacate from "./Bodies/Vacate/Vacate.vue";
import ProcessCheck from "./Bodies/ProcessCheck/ProcessCheck.vue";
import NewStatus from "./Bodies/NewStatus/NewStatus.vue";
import EditStatus from "./Bodies/EditStatus/EditStatus.vue";
import OrderFilters from "./Bodies/OrderFilters/OrderFilters.vue";
import PreviewOrder from "./Bodies/PreviewOrder/PreviewOrder.vue";
import ShipByUber from "./Bodies/ShipByUber/ShipByUber.vue";
import ChangeDueDate from "./Bodies/ChangeDueDate/ChangeDueDate.vue";
import Shipping from "./Bodies/Shipping/Shipping.vue";
import RelatedOrders from "./Bodies/RelatedOrders/RelatedOrders.vue";
import StatusWarning from "./Bodies/StatusWarning/StatusWarning.vue";
import ConfirmGroupPosition from "./Bodies/ConfirmGroupPosition/ConfirmGroupPosition.vue";
import InstructionDialog from "./Bodies/InstructionDialog/InstructionDialog.vue";
import ProductionFilter from "./Bodies/ProductionFilter/ProductionFilter.vue";
import NewInventory from "./Bodies/NewInventory/NewInventory.vue";
import NewSpot from "./Bodies/NewSpot/NewSpot.vue";
import InventoriesFilter from "./Bodies/InventoriesFilter/InventoriesFilter.vue";
import SendPreviewVerification from "./Bodies/SendPreviewVerification/SendPreviewVerification.vue";
import Anonymize from "./Bodies/Anonymize/Anonymize.vue";
import ProductionEstimateSettings from "./Bodies/ProductionEstimateSettings/ProductionEstimateSettings.vue";

export const BODIES = {
  Flag,
  ChangeStatus,
  Redo,
  Vacate,
  ProcessCheck,
  Assign,
  NewStatus,
  EditStatus,
  ApproveLaserTable,
  SendProof,
  OrderFilters,
  PreviewOrder,
  ShipByUber,
  ChangeDueDate,
  Shipping,
  RelatedOrders,
  ShippingVerification,
  StatusWarning,
  ConfirmGroupPosition,
  ShippingNotes,
  InstructionDialog,
  ProductionFilter,
  NewInventory,
  NewSpot,
  InventoriesFilter,
  SendPreviewVerification,
  Anonymize,
  ExportCustomerData,
  ProductionEstimateSettings,
};
