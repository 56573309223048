import request from "@/utils/request";

export const getProductionApi = (data) =>
  request({
    url: `/api/V2/production?${data}`,
    method: "get",
  });

export const productionTopApi = (data) =>
  request({
    url: "/api/V2/production/top",
    method: "post",
    data,
  });

export const productionAssignApi = (data) =>
  request({
    url: "/api/V2/production/assign",
    method: "post",
    data,
  });

export const productionEstimateApi = () =>
  request({
    url: `/api/V2/production-estimate`,
    method: "get",
  });

export const productionEstimateSettingsApi = (data) =>
  request({
    url: "/api/V2/production-estimate/settings",
    method: "post",
    data,
  });
