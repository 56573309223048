<template>
  <form v-if="!loading" action="false" @submit.prevent="handleSubmitAssign">
    <div class="modal_inputs">
      <Multiselect
        v-model="formData[PRINTER_ID]"
        :classes="{
          containerActive: 'ring ring-hqPurple ring-opacity-30',
          optionSelected: 'bg-hqPurple text-white',
          optionSelectedPointed: 'bg-hqPurple text-white',
        }"
        :name="PRINTER_ID"
        :options="lasersOptions"
        placeholder="Select a Printer"
        type="select"
      />
      <Multiselect
        v-if="permitted"
        v-model="formData[EXTRA_PRINTER_ID][0]"
        :classes="{
          containerActive: 'ring ring-hqPurple ring-opacity-30',
          optionSelected: 'bg-hqPurple text-white',
          optionSelectedPointed: 'bg-hqPurple text-white',
        }"
        :options="lasersOptions"
        placeholder="Select an Extra Printer"
        type="select"
      />
      <Multiselect
        v-if="permitted"
        v-model="formData[EXTRA_PRINTER_ID][1]"
        :classes="{
          containerActive: 'ring ring-hqPurple ring-opacity-30',
          optionSelected: 'bg-hqPurple text-white',
          optionSelectedPointed: 'bg-hqPurple text-white',
        }"
        :options="lasersOptions"
        placeholder="Select an Extra Printer"
        type="select"
      />
      <FormKit
        v-model="formData[LOAD_OPERATOR_NAME]"
        placeholder="Load operator name"
        :name="LOAD_OPERATOR_NAME"
        type="text"
      />
      <FormKit
        v-model="formData[APPLY_RULE]"
        :classes="{ fieldset: '!border-0 !p-0' }"
        :name="APPLY_RULE"
        label="Apply One Item Rule"
        type="checkbox"
        value=""
      />
      <FormKit
        v-model="formData[EXCLUDE_NCL]"
        :classes="{ fieldset: '!border-0 !p-0' }"
        :name="EXCLUDE_NCL"
        label="Exclude NCL Orders"
        type="checkbox"
        value=""
      />
      <FormKit
        v-model="formData[EXCLUDE_RCCL]"
        :classes="{ fieldset: '!border-0 !p-0' }"
        :name="EXCLUDE_RCCL"
        label="Exclude RCCL Orders"
        type="checkbox"
      />
      <FormKit
        v-model="formData[SKIP_RULE]"
        :classes="{ fieldset: '!border-0 !p-0' }"
        :name="SKIP_RULE"
        label="Ignore One Item Rule"
        type="checkbox"
      />
      <FormKit
        v-model="formData[SKIP_RULE_ROCKET]"
        :classes="{ fieldset: '!border-0 !p-0' }"
        :name="SKIP_RULE_ROCKET"
        label="Ignore 3 Days Rule"
        type="checkbox"
      />
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="handleCancel"></Button>
      <Button text="Assign" @click="handleSubmitAssign" />
    </div>
  </form>
  <Loading v-if="loading" class="py-32">Loading...</Loading>
</template>

<script setup>
import Multiselect from "@/components/UI/Form/Multiselect/Multiselect.vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import Loading from "@/components/UI/Loading/Loading.vue";
import {
  PRINTER_ID,
  APPLY_RULE,
  EXCLUDE_NCL,
  EXCLUDE_RCCL,
  SKIP_RULE,
  SKIP_RULE_ROCKET,
  EXTRA_PRINTER_ID,
  LOAD_OPERATOR_NAME,
} from "./Assign.consts";
import { useAssignDialog } from "./Assign.hook";

const props = defineProps({
  onSubmit: {
    type: Function,
    required: true,
  },
});

const { formData, loading, permitted, handleSubmitAssign, handleCancel, lasersOptions } =
  useAssignDialog(props.onSubmit);
</script>
