export const PRINTER_ID = "printer_id";
export const EXTRA_PRINTER_ID = "extra_printer";
export const APPLY_RULE = "apply_rule";
export const EXCLUDE_NCL = "exclude_ncl";
export const EXCLUDE_RCCL = "exclude_rccl";
export const SKIP_RULE = "skip_rule";
export const SKIP_RULE_ROCKET = "skip_rule_rocket";

export const LOAD_OPERATOR_NAME = "load_operator_name";

export const INITIAL_FILTERS = [
  { key: PRINTER_ID, init: null, type: "Number", required: true, name: "Printer ID" },
  {
    key: EXTRA_PRINTER_ID,
    init: [null, null],
    type: "Array",
    required: false,
    name: "Extra Printer ID",
  },
  { key: APPLY_RULE, init: false, type: "Boolean", required: false, name: "One Item Rule" },
  { key: EXCLUDE_NCL, init: false, type: "Boolean", required: false, name: "Exclude NCL Orders" },
  { key: EXCLUDE_RCCL, init: false, type: "Boolean", required: false, name: "Exclude RCCL Orders" },
  { key: SKIP_RULE, init: false, type: "Boolean", required: false, name: "One Item Rule" },
  { key: SKIP_RULE_ROCKET, init: false, type: "Boolean", required: false, name: "3 Days Rule" },
  { key: LOAD_OPERATOR_NAME, init: null, type: "String", required: true, name: "Operator Name" },
];
