import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { useModal } from "@/components/UI/Modal/useModal";
import { onMounted, ref } from "vue";
import { productionEstimateSettingsApi } from "@/api/production";
import { useProductionStoreActions } from "@/hooks/store/useProductionStore";

export const useProductionEstimateSettings = () => {
  const { loadLasersOptions, lasersOptions } = useOptionsStoreActions();
  const { close } = useModal();
  const { loadProductionEstimate, burnEstimate } = useProductionStoreActions();
  const formData = ref({
    printer_id: null,
    active_printers: null,
  });

  onMounted(() => {
    loadLasersOptions();
    formData.value.printer_id = burnEstimate?.value?.printer_id ?? null;
    formData.value.active_printers = burnEstimate?.value?.active_printers ?? null;
  });
  const handleSubmitAssign = () => {
    productionEstimateSettingsApi(formData.value).then(() => {
      loadProductionEstimate();
      close(null);
    });
  };
  const handleCancel = () => {
    close(null);
  };
  return {
    formData,
    lasersOptions,
    handleSubmitAssign,
    handleCancel,
  };
};
