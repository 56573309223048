<template>
  <form action="false" @submit.prevent="handleSubmitAssign">
    <div class="modal_inputs">
      <FormKit
        :classes="{
          containerActive: 'ring ring-hqPurple ring-opacity-30',
          optionSelected: 'bg-hqPurple text-white',
          optionSelectedPointed: 'bg-hqPurple text-white',
        }"
        v-model="formData.printer_id"
        placeholder="Select a Printer"
        :options="lasersOptions"
        type="select"
      />
      <FormKit
        v-model="formData.active_printers"
        @input="formData.active_printers = Number(formData.active_printers)"
        input-class="h-10 w-56"
        outer-class="m-0"
        placeholder="Active Printers"
        type="number"
        min="1"
      />
    </div>
    <div class="modal_actions">
      <Button secondaryButton text="Cancel" @click="handleCancel" />
      <Button text="Update Settings" type="submit" />
    </div>
  </form>
</template>
<script setup>
import Button from "@/components/UI/Form/Button/Button.vue";
import { useProductionEstimateSettings } from "@/components/UI/Modal/Bodies/ProductionEstimateSettings/ProductionEstimateSettings.hook";

const { formData, lasersOptions, handleSubmitAssign, handleCancel } =
  useProductionEstimateSettings();
</script>
